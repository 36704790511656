<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page: {
        title: "Méssage",
        meta: [{ name: "description" }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Méssage",
            items: [
                {
                    text: "Programme",
                    href: "/"
                },
                {
                    text: "Méssage",
                    active: true
                }
            ]
        };
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
    </Layout>
</template>